import React from 'react';
import { Layout as AntLayout, Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import LayoutNavigation from './LayoutNavigation';
import { actions } from './Layout.redux';

const { Header, Footer, Content, Sider } = AntLayout;
const year = new Date().getFullYear();

const Layout = (props) => {
	const dispatch      = useDispatch(),
		  loggedIn      = useSelector(state => state.auth.loggedIn),
		  title         = useSelector(state => state.layout.title),
		  menuCollapsed = useSelector(state => state.layout.menuCollapsed);

	const toggleShowMenu = () => dispatch(actions.setState({ menuCollapsed: !menuCollapsed }));


	return (
		<AntLayout>

			<Header className="ts_header">
				<Row>
					<Col>
						{
							(loggedIn && !menuCollapsed) && <MenuFoldOutlined className="ts_nav_collapse_icon" onClick={toggleShowMenu} />
						}
						{
							(loggedIn && menuCollapsed) && <MenuUnfoldOutlined className="ts_nav_collapse_icon" onClick={toggleShowMenu} />
						}
					</Col>
					<Col className="ts_header_title_container">
						<div className="header_logo" alt={import.meta.env.VITE_COMPANY_NAME} />
					</Col>
					<Col>
						<Typography.Title level={2} className="ts_header_title">
							{title}
						</Typography.Title>
					</Col>
				</Row>

			</Header>


			<AntLayout>
				{loggedIn &&
				<Sider trigger={null} collapsible collapsed={menuCollapsed} className="ts_sider_container">
					<LayoutNavigation />
				</Sider>
				}

				<div className="nonheader_wrapper">
					<Content className="ts_content">
						{props.children}
					</Content>

					<Footer className="ts_footer" align="center">
						&copy; {year} {import.meta.env.VITE_COMPANY_NAME}. All rights reserved. 0.7.3
					</Footer>
				</div>
			</AntLayout>


		</AntLayout>
	);
};


export default Layout;
